import { useEffect, useState } from 'react';
import { useTitle } from 'react-use';

import { Screen } from '../../components/common/Screen';
import {
  isGuest,
  useIsUserLoaded,
  useUser,
  useUserContext,
} from '../../components/UserContext';
import { getVenueSlug } from '../../components/Venue/VenueProvider';
import { type RedirectRule, useUserRedirect } from '../../hooks/useRedirection';
import { apiService } from '../../services/api-service';
import { HttpStatusCode } from '../../types/api';
import { type Venue } from '../../types/venue';
import { err2s, makeTitle } from '../../utils/common';

const title = 'Welcome to Luna Park Hosts';
const content = (
  <>
    <div>Host privileges have been activated for your account!</div>
    <div>You will now be taken to your new home.</div>
  </>
);

const HostAccessScreen = (): JSX.Element => {
  useTitle(makeTitle('Host Access'));
  const [inited, setInited] = useState<number>(0);
  const [venue, setVenue] = useState<Venue | null>(null);
  const [serverError, setServerError] = useState<Error | null>(null);
  const { resetUser, updateUser } = useUserContext();
  const user = useUser({ init: true });
  const isUserLoaded = useIsUserLoaded();
  const redirect = useUserRedirect();

  useEffect(() => {
    if (!isUserLoaded) return;
    if (isGuest(user)) {
      resetUser();
    }
  }, [user, resetUser, isUserLoaded]);

  useEffect(() => {
    if (inited < 1) return;
    const rules: RedirectRule[] = [{ kind: 'guest', target: '/signup' }];
    if (venue) {
      const redirectTo = `/host/venue/${getVenueSlug(venue)}`;
      rules.push({ kind: 'admin', target: redirectTo });
      rules.push({
        kind: 'organizer',
        target: `/venue/${getVenueSlug(venue)}`,
      });
      rules.push({
        kind: 'venueActivated',
        target: `/host/venue/${getVenueSlug(venue)}`,
      });
    }
    async function run() {
      const redirected = await redirect(rules);
      if (!redirected) setInited(2);
    }
    run();
  }, [redirect, venue, inited]);

  useEffect(() => {
    const init = async () => {
      try {
        const res = await apiService.venue.getMyVenue();
        setVenue(res.data.venue);
      } catch (error: UnassertedUnknown) {
        const status = error.response?.status ?? null;
        if (status !== HttpStatusCode.NOT_FOUND) {
          setServerError(error.response?.data?.msg || error.message);
        }
      }
      setInited(1);
    };
    init();
  }, []);

  const handleCreateVenue = async () => {
    setServerError(null);
    try {
      const res = await apiService.venue.createVenue();
      setVenue(res.data.venue);
      updateUser({ venueActivated: true });
    } catch (err: UnassertedUnknown) {
      setServerError(err.response?.data?.msg || err.message);
    }
  };

  if (inited < 2) return <></>;

  return (
    <div className='w-screen h-full pb-8 flex flex-col items-center'>
      <Screen title={title} content={content}>
        <div>
          <button
            type='button'
            className='btn-primary w-85 h-12.5 text-base mt-6'
            onClick={handleCreateVenue}
          >
            Take Me to My Venue
          </button>
        </div>
        <div className='absolute bottom-4 text-red-005 text-sms'>
          {err2s(serverError)}
        </div>
      </Screen>
    </div>
  );
};

// Easier to lazy import
// eslint-disable-next-line import/no-default-export
export default HostAccessScreen;
